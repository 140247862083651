import React from 'react';

const AppAdsTxt = () => {
  return (
    <pre>
    google.com, pub-2798556376519151, DIRECT, f08c47fec0942fa0
    </pre>
  );
};

export default AppAdsTxt;
